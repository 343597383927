import React from "react";
import about from "../assets/images/about-2.jpg";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-img">
        <img src={about} alt="Claire shooting" />
      </div>
      <div className="about-presentation">
        <div className="about-title">
          <h2>À propos</h2>
        </div>
        <p>
          Passionnée par l’artisanat, j’ai toujours aimé aller à la rencontre
          des artisans et producteurs, qui ont des étoiles plein les yeux quand
          ils racontent leurs histoires et anecdotes. C’est alors que j'ai
          décidé de retranscrire leurs histoires en images, avec mon appareil
          photo.
        </p>
        <p>
          Je réalise des reportages photographiques, partage des moments de vie,
          des gestes rares, des savoir-faire, des produits et des découvertes de
          tous secteurs. L'inspiration que je glane chez les uns, sera
          communiquée chez les autres. J’aide marques, artisans, médias et
          entreprises à développer des contenus visuels uniques.
        </p>
        <p>
          Après avoir habité à Paris, Londres, Milan et Montréal, j’ai atterri
          en Bretagne, à Rennes. La valise reste toujours prête pour voyager.
        </p>
        <p>
          Curieuse, je suis toujours partante pour une nouvelle aventure, mettre
          en lumière des expériences actives qui connectent au monde et à
          soi-même. Quelle sera la prochaine ? J’espère avec vous.
        </p>
        <p className="Claire">
          Claire<span>.</span>
        </p>

        <div className="social-contact">
          <p className="links">
            <a href="mailto:contact@clairepanizzolo.com">
              contact<span className="arobase">@</span>clairepanizzolo.com
            </a>
          </p>
          <span className="vertical-line">|</span>
          <p className="links">
            <a
              href="https://www.instagram.com/clairepanizzolo/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
