import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile ? (
    <CookieConsent
      location="bottom"
      buttonText="J'accepte"
      declineButtonText="Je refuse"
      cookieName="CookiesConsent"
      style={{
        background: "white",
        color: "black",
        height: "140px",
        position: "fixed",
        fontSize: "13px",
        lineHeight: "20px",
      }}
      buttonStyle={{
        color: "white",
        fontSize: "13px",
        background: "rgba(238, 128, 38)",
      }}
      declineButtonStyle={{
        color: "black",
        border: "0.5px solid rgba(238, 128, 38)",
        background: "white",
      }}
      expires={150}
      enableDeclineButton
    >
      Ce site internet utilise des cookies pour améliorer votre expérience
      utilisateur. Pour en savoir plus, consultez nos{" "}
      <span>
        <Link to="/mentions" style={{ color: "rgba(238, 128, 38)" }}>
          mentions légales
        </Link>
        .
      </span>{" "}
    </CookieConsent>
  ) : (
    <CookieConsent
      location="bottom"
      buttonText="J'accepte"
      declineButtonText="Je refuse"
      cookieName="CookiesConsent"
      style={{
        background: "white",
        color: "black",
        height: "60px",
        position: "fixed",
        lineHeight: "15px",
      }}
      buttonStyle={{
        color: "white",
        fontSize: "13px",
        background: "rgba(238, 128, 38)",
        padding: "10px",
      }}
      declineButtonStyle={{
        color: "black",
        border: "0.5px solid rgba(238, 128, 38)",
        background: "white",
        padding: "10px",
      }}
      expires={150}
      enableDeclineButton
    >
      Ce site internet utilise des cookies pour améliorer votre expérience
      utilisateur. Pour en savoir plus, consultez nos{" "}
      <span>
        <Link to="/mentions" style={{ color: "rgba(238, 128, 38)" }}>
          mentions légales
        </Link>
        .
      </span>{" "}
    </CookieConsent>
  );
};

export default CookieBanner;
