import React, { useState, useEffect } from "react";
import ServicesPart from "../assets/images/ServicesAndContact/services-right-part.webp";
import Cta from "./Cta";
import Fade from "./Fade";
import { useLocation } from "react-router-dom";

function ServicesImage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const [showFade, setShowFade] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFade(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]);

  return isMobile ? (
    <div className="image-container">
      <Cta />
    </div>
  ) : (
    <div className="image-container">
      <img src={ServicesPart} alt="Contact" className="image" />
      <Fade show={showFade}>
        <Cta
          styles={{
            position: "absolute",
            top: "150px",
            left: "320px",
            marginTop: "50px",
            width: "350px",
          }}
        />
      </Fade>
    </div>
  );
}

export default ServicesImage;
